<template>
  <div>
    <router-view></router-view>
<!--    <div>-->
<!--      <page-footer></page-footer>-->
<!--    </div>-->
    <main-tools></main-tools>
  </div>
</template>

<script>
  import MainTools from './../main-tools'
  export default {
    name: 'prefecture',
    components: {
      MainTools
    },
    data () {
      return {
        prefectureKey: 'zryt-dc',
        prefectureName: ''
      }
    },
    created () {
      this.prefectureKey = this.$route.params.prefectureKey
      this.prefectureName = this.$route.params.prefectureName
    }
  }
</script>

<style scoped>

</style>
